
.sample-result-text {
  font-size: 0.8rem;
  color: #000
}

.sample-data-informations-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  border-radius: 10px;
  background-color: #ffffff;
}

.sample-data-informations-content {
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  padding: 0.5rem;
}

.border-grey {
  border: "0.1px solid lightgrey"
}