.container-sample-group {
  margin-top: 2rem;
  border-radius: 0.5rem;
}

.header-sample-group {
  padding: 1rem;
  border-bottom: 0.1rem solid black;
}

.content-sample-group {
  padding: 1rem;
}

.header-text-sample-group {
  font-size: 1rem;
  color: black;
}

.container-loading-sample-result-body {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem;
}