.asideSectionTitle {
  text-transform: uppercase;
  opacity: 0.7;
  font-size: 0.8rem;
  display: block;
  margin-bottom: 0.7rem;
}

.list {
  list-style: none;
  padding: 0;
}

.asideListItem {
  display: flex;
  gap: 0.5rem;
  margin-bottom: 0.7rem;
  align-items: center;
  padding: 0.3rem;
  border-radius: 0.5rem;
}

.asideListItemActive,
.asideListItem:hover {
  background-color: #f0f0f0;
  color: black;
}

.asideListItem:hover {
  cursor: pointer;
}

.configurationTitle {
  font-size: 1.2rem;
}

.configurationListItem {
  display: flex;
  justify-content: space-between;
}

.configurationSectionTitle {
  display: block;
}

.configurationTitle,
.configurationSectionTitle {
  font-weight: 600;
  color: #000000;
}

.secondaryText {
  font-size: 0.8rem;
}
