.container-stocable{
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr 24fr;
  margin: 10px 0;
  background-color: rgb(233, 232, 232);
  border-radius: 5px;
  padding-right: 15px;
}

.content-icon-stocable{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  background-color: red;
  margin-right: 10px;
  color: #ffffff;
}