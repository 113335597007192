.distributionButton {
  margin: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modalContainer {
  max-width: 1000px;
}

.cardHeader {
  font-weight: bold;
  color: black;
}

.cardContent,
.cardHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.closeModalButton {
  width: 130px;
}

.mapContainer {
  height: 45rem;
}
